import post1 from './blog-post.1.md';
import post2 from './blog-post.2.md';
import post3 from './blog-post.3.md';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';

export const sections = [
  { title: 'Home', url: '/' },
  { title: 'Cafeterías', url: '#' },
  { title: 'Mapa', url: '#' },
];

export const mainFeaturedPost = {
  title: 'Cafe cacao',
  description:
    "La cafetería que puede ser tu oficina",
  image: 'https://source.unsplash.com/random',
  imageText: 'main image description',
  linkText: 'Continuar leyendo…',
};

export const featuredPosts = [
  {
    title: 'Featured post',
    date: 'Nov 12',
    description:
      'This is a wider card with supporting text below as a natural lead-in to additional content.',
    image: 'https://source.unsplash.com/random',
    imageLabel: 'Image Text',
  },
  {
    title: 'Post title',
    date: 'Nov 11',
    description:
      'This is a wider card with supporting text below as a natural lead-in to additional content.',
    image: 'https://source.unsplash.com/random',
    imageLabel: 'Image Text',
  },
];

export const posts = [post1, post2, post3];


export const sidebar = {
  title: 'Coffee Among Walls',
  description:
    [
      'Como ávido consumidor del café y los productos que lo orbitan, decidí compartir mi opinión de los lugares que visito por simple gusto.',
      'Quizá el lector se pregunte quién soy para opinar, la respuesta no podría ser más simple, solo un cliente cualquiera.',
      'Espero mis opiniones sean entretenidas de leer o escuchar y en el mejor de los casos, útiles para alguien.',
      'Atte. O.R.P.G.',
    ],
  archives: [
    { title: 'March 2020', url: '#' },
    { title: 'February 2020', url: '#' },
    { title: 'January 2020', url: '#' },
    { title: 'November 1999', url: '#' },
    { title: 'October 1999', url: '#' },
    { title: 'September 1999', url: '#' },
    { title: 'August 1999', url: '#' },
    { title: 'July 1999', url: '#' },
    { title: 'June 1999', url: '#' },
    { title: 'May 1999', url: '#' },
    { title: 'April 1999', url: '#' },
  ],
  social: [
    { name: 'Twitter', icon: TwitterIcon },
    { name: 'Instagram', icon: InstagramIcon },
  ],
};
