import * as React from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { sections, sidebar } from '../../dummyData/dummyData';
import Header from '../../Components/Header';
import MainFeaturedPost from '../../Components/MainFeaturedPost';
import FeaturedPost from '../../Components/FeaturedPost';
import Main from '../../Components/Main';
import Sidebar from '../../Components/Sidebar';
import Footer from '../../Components/Footer';
import axios from 'axios';
import Loader from '../../Components/Loader';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';



export default function Blog() {

  const [posts, setPosts] = React.useState(null);
  const [mainFeaturedPost, setMainFeaturedPost] = React.useState(null);
  const [featuredPosts, setFeaturedPosts] = React.useState(null);

  React.useEffect(()=>{
    axios.get('https://yslz9jg141.execute-api.us-east-1.amazonaws.com/posts').then((postsPayload)=>{
      console.log(postsPayload);
      const {data} = postsPayload;
      const {pages} = data || {};

      setPosts(pages || []);
    })
  },[]);
  React.useEffect(()=>{
    axios.get('https://yslz9jg141.execute-api.us-east-1.amazonaws.com/featured-posts').then((postsPayload)=>{
      const {data:pages} = postsPayload;
      const mainPost = pages?.mainPosts[0];
      const secondaryPosts = pages?.mainPosts.slice(1,3);
      setMainFeaturedPost(mainPost);
      setFeaturedPosts(secondaryPosts || []);
    })
  },[]);
  React.useEffect(()=>{
    axios.get('https://yslz9jg141.execute-api.us-east-1.amazonaws.com/post-data').then((postsPayload)=>{
      const {data:pages} = postsPayload;
      const postsArchiveOptions = pages?.posts.reduce((accumulator, current)=>{
        const monthOfPost = format(new Date(current.creationTime), 'LLLL', { locale: es })
        return accumulator[monthOfPost] = (accumulator[monthOfPost]||0)+1;
      },{});
      debugger;
    }).catch(e => {
      console.log(e);
      debugger;
    })
  },[]);


  return (
    <>
      <Container maxWidth="lg">
        <Header title="Coffee Among Walls" sections={sections} />
        {!!posts?<main>
          {!!mainFeaturedPost && <MainFeaturedPost post={mainFeaturedPost} />}
          <Grid container spacing={4}>
            {!!featuredPosts && featuredPosts.map((post) => (
              <FeaturedPost key={post.title} post={post} />
            ))}
          </Grid>
          <Grid container spacing={5} sx={{ mt: 3 }}>
            <Main title="Desde la tostadora..." posts={posts} />
            <Sidebar
              title={sidebar.title}
              description={sidebar.description}
              archives={sidebar.archives}
              social={sidebar.social}
            />
          </Grid>
        </main>:<Loader/>}
      </Container>
      <Footer
        title="Powered by Quepar Solutions"
      />
    </>
  );
}