import * as React from 'react';
import ReactMarkdown from 'markdown-to-jsx';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CustomLink from './CustomLink';

function MarkdownListItem(props) {
  return <Box component="li" sx={{ mt: 1, typography: 'body1' }} {...props} />;
}

const options = {
  overrides: {
    h1: {
      component: Typography,
      props: {
        gutterBottom: true,
        variant: 'h4',
        component: 'h1',
      },
    },
    h2: {
      component: Typography,
      props: { gutterBottom: true, variant: 'h5', component: 'h2', sx:{fontWeight: "bold", textAlign: "justify"} },
    },
    h3: {
      component: Typography,
      props: { gutterBottom: true, variant: 'h6', sx:{textDecoration:"underline", textAlign: "start", fontWeight: "bold"}},
    },
    h4: {
      component: Typography,
      props: {
        gutterBottom: true,
        variant: 'caption',
        paragraph: true,
      },
    },
    p: {
      component: Typography,
      props: { paragraph: true, sx:{textAlign: "justify"} },
    },
    a: { component: CustomLink },
    li: {
      component: MarkdownListItem,
    },
    img: {
      component: "img",
      props:{
        style:{
          maxWidth: "100%",
        }
      }
    }
  },
};

export default function Markdown(props) {
  return <ReactMarkdown options={options} {...props} />;
}
