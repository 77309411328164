import { CssBaseline, ThemeProvider } from '@mui/material'
import React from 'react'
import { Route, Routes } from 'react-router-dom'
import './App.css'
import { newMuiTheme } from './theme'
import Blog from './views/MainView/MainPage'
import PostView from './views/PostView/PostView'

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={newMuiTheme}>
      <CssBaseline />
      <Routes>
        <Route path="/" element={<Blog/>}/>
        <Route path="/post/:postId" element={<PostView/>}/>
      </Routes>
      </ThemeProvider>
    </div>
  )
}

export default App
