import { Container } from '@mui/material';
import axios from 'axios';
import React from 'react';
import { useParams } from 'react-router-dom';
import Header from '../../Components/Header';
import Loader from '../../Components/Loader';
import Post from '../../Components/Post';
import { sections } from '../../dummyData/dummyData';

const PostView = ()=>{

    const [content, setContent] = React.useState(null);
    const {postId} = useParams();

    React.useEffect(()=>{
        if(postId){
            axios.get(`https://yslz9jg141.execute-api.us-east-1.amazonaws.com/post/${postId}`).then((payload)=>{
                setContent(payload.data.content);
            });
        }
    },[postId])

    return <Container maxWidth="lg">
        <Header title="Coffee Among Walls" sections={sections} />
        <main>
            {content ? <Post content={content}/>:<Loader/>}
        </main>
    </Container>
};


export default PostView;