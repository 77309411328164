import { createTheme } from '@mui/material/styles';

export const newMuiTheme = createTheme({
  palette:{
    mode: "dark",
    primary: {
      main: '#795548',
    },
    secondary: {
      main: '#ffecb3',
    },
  },
  typography: {
    fontFamily:['Source Code Pro', 'monospace']
  }
})