import { Box, Link } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import React from 'react';

const CustomLink = (props)=>{
    console.log(props);
    const {href} =props;
    if(href?.includes("maps")){
        console.log(href);
        return <Box display="flex" justifyContent="center">
            <LocationOnIcon/>
            <Link sx={{color:'secondary.main'}} {...props}>Dirección</Link>
        </Box>
    }else{
        return <Link {...props}/>
    }
}

export default CustomLink;